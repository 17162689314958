/* eslint-disable jsx-a11y/no-autofocus */
/* eslint-disable no-nested-ternary */
/* eslint-disable eqeqeq */
import { format } from 'date-fns'
import React, { useEffect, useState } from 'react'
import { Button, Col, FormCheck, Modal, Row } from 'react-bootstrap'
import { toast } from 'react-toastify'
import { receiveBloodComponent, refuseBloodComponent } from '../../service'
import { InputInstructions, Label, ModalStyles } from './styles'
import { ReceiveBloodComponentModalProps } from './types'

const ReceiveBloodComponentModal: React.FC<ReceiveBloodComponentModalProps> = ({
  list,
  show,
  handleClose,
  handleConfirm,
}) => {
  const [searchedComponents, setSearchedComponents] = useState(null)
  const [search, setSearch] = useState('')
  const [justification, setJustification] = useState('')
  const [isRecused, setIsRecused] = useState(false)
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);

  const getName = () => sessionStorage.getItem('responsible') || ''

  const clear = () => {
    setSearch('')
    setJustification('')
    setSearchedComponents(null)
    setIsRecused(false)
  }

  const handleTypeSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearch(e.target.value)
  }

  const handleCancel = () => {
    setSearch('')
    setSearchedComponents(null)
    handleClose()
  }

  const handleSearch = () => {
    const filterdComponents = list.find((component) => component.bagNumber == search)

    if (!filterdComponents) {
      toast.info('Não há hemocomponentes com esse código!')
      setSearchedComponents(null)
      return
    }

    if ('status' in filterdComponents) {
      toast.info(`Hemocomponente já ${filterdComponents.status ? 'recebido' : 'recusado'}`)
      setSearchedComponents(null)
      return
    }

    setSearchedComponents(filterdComponents)
    setSearch('')
  }

  const doReceiveBloodComponent = async () => {
    try {
      await receiveBloodComponent({
        bloodComponentRecevedDto: {
          requestItemId: searchedComponents.requestItemId,
          transfusionRequestId: searchedComponents.transfusionRequestId,
          bloodComponentOutputId: searchedComponents.bloodComponentOutputId,
          responsable: getName(),
        }
      })
      toast.success('Hemocomponente recebido com sucesso')
      handleConfirm();
    } catch (error) {
      toast.error('Algo deu errado. Tente novamente mais tarde.')
    }
  }

  const doRefuseBloodComponent = async () => {
    try {
      await refuseBloodComponent({
        bloodComponentRefusedDto: {
          transfusionRequestId: searchedComponents.transfusionRequestId,
          bloodComponentOutputId: searchedComponents.bloodComponentOutputId,
          justification,
          responsable: getName(),
        }
      })
      toast.success('Hemocomponente recusado com sucesso')
      handleConfirm()
    } catch (error) {
      toast.error('Algo deu errado. Tente novamente mais tarde.')
    }
  }

  const handleConfirmButtonClick = () => {

    const expirationDate = new Date(searchedComponents.expirationDate);

    const currentDate = new Date();

    if (currentDate > expirationDate) {
      setShowConfirmationModal(true);
      toast.error('Validade expirada!');
    } else {
      doReceiveBloodComponent();
    }

  };

  const handleConfirmationModalClose = () => {
    setShowConfirmationModal(false);
  };

  const handleConfirmationModalConfirm = () => {
    doReceiveBloodComponent();

    setShowConfirmationModal(false);
  };

  useEffect(() => {
    clear()
  }, [show])

  return (
    <ModalStyles show={show}>
      <Modal.Header>
        <h3 className='modal-title'>Faça a leitura do hemocomponente</h3>
      </Modal.Header>
      <Modal.Body>
        <div>
          <Row style={{ marginBottom: 8 }}>
            <div>
              <Col className='barcode-input'>
                <div>
                  <Label style={{ textAlign: 'center', marginBottom: '0px' }}>
                    Posicione a linha sobre o código de barras e aguarde a leitura.
                  </Label>
                </div>
              </Col>
              <InputInstructions>
                <hr style={{ color: '#757575', width: '100px' }} />
                <p style={{ color: '#757575', width: '10%' }}>ou</p>
                <hr style={{ color: '#757575', width: '100px' }} />
              </InputInstructions>
              <div>
                <Label style={{ textAlign: 'center', marginBottom: '7px' }}>
                  Caso não tenha leitor disponível use o campo abaixo:
                </Label>
              </div>
            </div>
          </Row>
          <Row>
            <div
              className='component-researcher'
              style={{
                alignContent: 'center',
                flexDirection: 'column',
                display: 'flex',
              }}
            >
              <input
                id='researcher'
                value={search}
                onChange={(e) => handleTypeSearch(e)}
                autoFocus
                onKeyDown={({ key }) => (key === 'Enter' ? handleSearch() : null)}
              />

              <Button
                size='sm'
                variant='primary'
                onClick={handleSearch}
                disabled={!search}
                style={{ width: 'fit-content', margin: 'auto', marginTop: '15px' }}
              >
                Pesquisar hemocomponente
              </Button>
            </div>
          </Row>
        </div>
        <hr
          style={{
            height: '2px',
            backgroundColor: '#000000',
          }}
        />
        <div>
          <Row>
            <Col className='justify-content-center d-flex flex-wrap'>
              <table style={{ width: '100%' }}>
                <tbody>
                  <tr style={{ width: '100%' }}>
                    <th className='theader'>
                      <span>Hemocomponente:</span>
                    </th>
                    <th className='theader'>
                      <span>Temp.:</span>
                    </th>
                    <th
                      className='theader'
                      style={{
                        paddingRight: '15px',
                      }}
                    >
                      <span>Qtd:</span>
                    </th>
                    <th className='theader'>
                      <span>UM:</span>
                    </th>
                    <th className='theader'>
                      <span>Data de Validade:</span>
                    </th>
                  </tr>
                  {searchedComponents ? (
                    <tr key={searchedComponents.bagNumber}>
                      <td className='tdata'>
                        <span style={{ color: '#000000 !important' }}>
                          {searchedComponents.bloodComponentName}
                        </span>
                      </td>
                      <td className='tdata'>
                        <span style={{ color: '#000000 !important' }}>
                          {searchedComponents.temperature}
                        </span>
                      </td>
                      <td className='tdata'>
                        <span style={{ color: '#000000 !important' }}>
                          {searchedComponents.quantity}
                        </span>
                      </td>
                      <td className='tdata'>
                        <span style={{ color: '#000000 !important' }}>
                          {searchedComponents.unitMeasurement}
                        </span>
                      </td>
                      <td className='tdata'>
                        <span style={{ color: '#000000 !important' }}>
                          {format(new Date(searchedComponents.expirationDate), 'dd/MM/yyyy HH:mm')}
                        </span>
                      </td>
                    </tr>
                  ) : null}
                </tbody>
              </table>
            </Col>
          </Row>
          <Row style={{ marginBottom: 24,width: 'max-content' }}>
            <Col style={{ display: 'flex', alignItems: 'center' }} class='col-md-6'>
              <FormCheck
                label='Recusar Hemocomponente'
                onClick={() => {
                  setIsRecused((cur) => !cur)
                }}
                checked={isRecused}
                disabled={!searchedComponents}
              />
            </Col>
            <Col class='col-md-6'>
              <Row>
                <Col
                  style={{ width: 'auto', flex: 'inherit' }}
                  className='align-content-center w-auto'
                >
                  <Button size='sm' variant='outline-secondary' onClick={handleClose}>
                    Cancelar
                  </Button>
                </Col>

                <Col style={{ width: 'auto', flex: 'inherit' }}>
                  <Button
                    size='sm'
                    disabled={!searchedComponents || isRecused}
                    variant='primary'
                    onClick={handleConfirmButtonClick}
                  >
                    Confirmar
                  </Button>
                </Col>
              </Row>
            </Col>
          </Row>
          {isRecused ? (
            <Row>
              <Col className='justify-area'>
                <Label>Justificativa:</Label>
                <textarea
                  id='justification'
                  value={justification}
                  onChange={(event: any) => {
                    const enteredValue = event.target.value

                    if (enteredValue.length <= 200) {
                      setJustification(enteredValue)
                    }
                  }}
                />
              </Col>
            </Row>
          ) : null}
        </div>
      </Modal.Body>
      {isRecused ? (
        <Modal.Footer>
          <Row style={{ width: '100%' }} className='justify-content-end '>
            <Col style={{ width: 'auto', flex: 'inherit' }}>
              <Button size='sm' variant='outline-secondary' onClick={handleCancel}>
                Cancelar
              </Button>
            </Col>
            <Col style={{ width: 'auto', flex: 'inherit' }}>
              <Button
                size='sm'
                variant='primary'
                disabled={justification.length < 5 || justification.length > 200}
                onClick={doRefuseBloodComponent}
              >
                Recusar Hemocomponente
              </Button>
            </Col>
          </Row>
        </Modal.Footer>
      ) : null}
      <Modal show={showConfirmationModal} onHide={handleConfirmationModalClose}>
        <Modal.Header closeButton>
          <Modal.Title>Validade expirada!</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Você tem certeza de que deseja confirmar o hemocomponente?
        </Modal.Body>
        <Modal.Footer>
          <Button variant='secondary' onClick={handleConfirmationModalClose}>
            Cancelar
          </Button>
          <Button variant='primary' onClick={handleConfirmationModalConfirm}>
            Confirmar
          </Button>
        </Modal.Footer>
      </Modal>
    </ModalStyles>
  )
}

export default ReceiveBloodComponentModal
